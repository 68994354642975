// Generated by Framer (6f17e57)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {LdyUJUED8: {hover: true, pressed: true}};

const cycleOrder = ["LdyUJUED8"];

const variantClassNames = {LdyUJUED8: "framer-v-9rdegm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string; link?: string; tap?: any }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "LdyUJUED8", title: rSGCYmueV = "See All 128 Testimonials", link: bcuOtgElj, tap: kihgG8Sch, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "LdyUJUED8", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapimu9r2 = activeVariantCallback(async (...args) => {
if (kihgG8Sch) {
const res = await kihgG8Sch(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-E6coW", classNames)} style={{display: "contents"}}>
<Link href={bcuOtgElj}><motion.a {...restProps} className={`${cx("framer-9rdegm", className)} framer-1aysc52`} data-border data-framer-name={"Button"} data-highlight layoutDependency={layoutDependency} layoutId={"LdyUJUED8"} onTap={onTapimu9r2} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "rgb(230, 230, 230)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}} transition={transition} variants={{"LdyUJUED8-hover": {backgroundColor: "rgb(242, 242, 242)"}, "LdyUJUED8-pressed": {backgroundColor: "rgb(230, 230, 230)"}}} {...addPropertyOverrides({"LdyUJUED8-hover": {"data-framer-name": undefined}, "LdyUJUED8-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7UG9wcGlucy02MDA=", "--framer-font-family": "\"Poppins\", sans-serif", "--framer-font-size": "17px", "--framer-font-weight": "600", "--framer-line-height": "28px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>See All 128 Testimonials</motion.p></React.Fragment>} className={"framer-1ieuqte"} data-framer-name={"See All 128 Testimonials"} fonts={["GF;Poppins-600"]} layoutDependency={layoutDependency} layoutId={"CxeznHH8c"} style={{"--extracted-r6o4lv": "rgb(22, 22, 22)", "--framer-paragraph-spacing": "0px"}} text={rSGCYmueV} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.a></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-E6coW [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-E6coW .framer-1aysc52 { display: block; }", ".framer-E6coW .framer-9rdegm { align-content: flex-start; align-items: flex-start; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 16px 32px 16px 32px; position: relative; text-decoration: none; width: 960px; will-change: transform; }", ".framer-E6coW .framer-1ieuqte { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-E6coW .framer-v-9rdegm .framer-9rdegm { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-E6coW .framer-9rdegm { gap: 0px; } .framer-E6coW .framer-9rdegm > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-E6coW .framer-9rdegm > :first-child { margin-left: 0px; } .framer-E6coW .framer-9rdegm > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 60
 * @framerIntrinsicWidth 960
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"nogTDF0OY":{"layout":["fixed","auto"]},"sM_LooCEi":{"layout":["fixed","auto"]}}}
 * @framerVariables {"rSGCYmueV":"title","bcuOtgElj":"link","kihgG8Sch":"tap"}
 */
const FramerEr_B3UAka: React.ComponentType<Props> = withCSS(Component, css, "framer-E6coW") as typeof Component;
export default FramerEr_B3UAka;

FramerEr_B3UAka.displayName = "Button – Tertiary";

FramerEr_B3UAka.defaultProps = {height: 60, width: 960};

addPropertyControls(FramerEr_B3UAka, {rSGCYmueV: {defaultValue: "See All 128 Testimonials", displayTextArea: false, title: "Title", type: ControlType.String}, bcuOtgElj: {title: "Link", type: ControlType.Link}, kihgG8Sch: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerEr_B3UAka, [{family: "Poppins", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/Er_B3UAka:default", url: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf", weight: "600"}])